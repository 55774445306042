import React from 'react';
import {moduleOptions, questionOptions, submoduleOptions} from "../../data/adminData";

const AdminRights = ({handleRightsChange, rights}) => {
    return (
        <>
            <div>
                Модули
                {moduleOptions.map(option => (
                    <div style={{display: "flex", gap: "5px", marginLeft: '15px'}}>
                        <input type="checkbox" id={option.value} value={option.value} checked={rights.includes(option.value)}
                               onChange={handleRightsChange}/>
                        <label htmlFor={option.value}>{option.title}</label>
                    </div>
                ))}
            </div>
            <div>
                Подмодули
                {submoduleOptions.map(option => (
                    <div style={{display: "flex", gap: "5px", marginLeft: '15px'}}>
                        <input type="checkbox" id={option.value} value={option.value} checked={rights.includes(option.value)}
                               onChange={handleRightsChange}/>
                        <label htmlFor={option.value}>{option.title}</label>
                    </div>
                ))}
            </div>
            <div>
                Вопросы
                {questionOptions.map(option => (
                    <div style={{display: "flex", gap: "5px", marginLeft: '15px'}}>
                        <input type="checkbox" id={option.value} value={option.value} checked={rights.includes(option.value)}
                               onChange={handleRightsChange}/>
                        <label htmlFor={option.value}>{option.title}</label>
                    </div>
                ))}
            </div>
        </>
    );
};

export default AdminRights