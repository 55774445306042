import api from './api';

export const getAssembliesRequest = async () => api.get(`/assemblies`);

export const getAssemblyRequest = async (id) => api.get(`/assemblies/${id}`);

export const createAssemblyRequest = async (assembly) => api.post(`/assemblies`, assembly);

export const getAssemblyQuestionsRequest = async (id) => api.get(`/assemblies/${id}/questions`);

export const updateAssemblyRequest = async (id, data) => api.put(`/assemblies/${id}`, data);