import React from 'react';
import './Preloader.css'

const Preloader = ({show}) => {
    console.log(show)
    return (
        <div id="proloader_outer" style={{display: show ? 'block' : 'none'}}>
            <div id="preloader"></div>
        </div>
    );
};

export default Preloader