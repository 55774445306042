import React, {useState} from 'react';
import {toast} from "react-toastify";

const EditQuestionForm = ({data, onSubmit, submoduleData}) => {
    const [answersCount, setAnswersCount] = useState(data.answers.length || 2)
    const [answers, setAnswers] = useState(data.answers || [])
    const [correctAnswersIndexes, setCorrectAnswersIndexes] = useState(data.correctAnswersIndexes || [])
    const [formId, setFormId] = useState(data.id || '')
    const [question, setQuestion] = useState(data.question || '')
    const [correctAnswerText, setCorrectAnswerText] = useState(data.correctAnswer || '')


    const addAnswerHandler = (e) => {
        e.preventDefault()
        setAnswersCount(prev => prev + 1)
    }

    const removeAnswerHandler = (e) => {
        e.preventDefault()
        if (answersCount > 2) setAnswersCount(prev => prev - 1)
    }



    const handleAnswersChange = (e) => {
        const answersCopy = [...answers]
        const index = e.target.id.split('_')[1]
        answersCopy[index] = e.target.value
        setAnswers(answersCopy)
    }

    const handleCorrectAnswerChange = (e) => {
        if(correctAnswersIndexes.includes(+e.target.value)) {
            setCorrectAnswersIndexes(prev => prev.filter(index => index !== +e.target.value))
        } else {
            setCorrectAnswersIndexes(prev => [...prev, +e.target.value])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!formId || isNaN(formId) || formId < submoduleData.idIn || formId > submoduleData.idOut) {
            toast.error('Введите правильный id', {
                position: "top-right",
                autoClose: 3000,
            })
            return
        }
        if (!question || answers.length < 2 || !correctAnswerText || correctAnswersIndexes.length === 0) {
            toast.error('Заполните все поля', {
                position: "top-right",
                autoClose: 3000,
            })
            return
        }

        onSubmit({
            id: +formId,
            question,
            answers,
            correctAnswersIndexes,
            correctAnswer: correctAnswerText
        })
    }

    return (
        <form className='filterForm' onSubmit={handleSubmit}>
            <div className='formBox'>
                <label htmlFor="id">
                    id
                </label>
                <input type="text" id="id" value={formId} disabled/>
            </div>

            <div className='formBox'>
                <label htmlFor="question">
                    Вопрос
                </label>
                <input type="text" id="question" value={question} onChange={(e) => setQuestion(e.target.value)}/>
            </div>

            {Array(answersCount).fill(0).map((_, index) => (
                <div className='formBox' key={index}>
                    <label htmlFor={`answer_${index}`}>
                        Ответ {index + 1}
                    </label>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <input type="text" value={answers[index]} onChange={handleAnswersChange} id={`answer_${index}`}/>
                        <input type="checkbox"
                               checked={correctAnswersIndexes.includes(index)}
                               value={index}
                               onChange={handleCorrectAnswerChange}
                               name='rightAnswer'/>
                    </div>
                </div>
            ))}
            <div className="formBox formBoxButtons">
                <button onClick={addAnswerHandler}>+</button>
                <button onClick={removeAnswerHandler}>-</button>

            </div>

            <div className='formBox'>
                <label htmlFor="rightAnswerText">
                    Текст верного ответа
                </label>
                <input type="text" id="rightAnswerText" value={correctAnswerText}
                       onChange={(e) => setCorrectAnswerText(e.target.value)}/>
            </div>

            <div className='formBox'>
                <button className="button greenButton">
                    Обновить
                </button>
            </div>


        </form>
    );
};

export default EditQuestionForm