const initialState = {
    admin: {},
    admins: [],
}

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADMIN_LOGIN':
            return {...state, admin: action.payload};
        case 'ADMIN_CREATE':
            return {...state, admins: [...state.admins, action.payload]};
        case 'ADMINS_GET':
            return {...state, admins: action.payload};

        case 'ADMIN_EDIT':
            return {...state, admins: state.admins.map(admin => admin._id === action.payload._id ? action.payload : admin)};

        default:
            return state;
    }
}

export default adminReducer;