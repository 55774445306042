import React, {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import './AllModulesPage.css'
import {useDispatch, useSelector} from "react-redux";
import {getModulesAction} from "../../../redux/actions/moduleActions";

export default function AllModulesPage() {
    const {modules} = useSelector(state => state.moduleReducer)
    const [filterCategory, setFilterCategory] = useState('All')

    const {admin} = useSelector(state => state.adminReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getModulesAction())
    }, []);

    let modulesFiltered = modules.filter(module => {
        if (filterCategory === 'All') {
            return true
        } else {
            return module.category._id === filterCategory
        }
    })

    const uniqueCategories = [...new Set(modules.map(module => module.category._id))]
    const categories = uniqueCategories.map(categoryId => {
        return modules.find(module => module.category._id === categoryId).category
    })
    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <span>Управление модулями</span>
            </div>


            <form className='filterForm'>
                <div className='formBox'>
                    <label htmlFor="category">
                        Фильтр
                    </label>
                    <select name="" id="category" onChange={e => setFilterCategory(e.target.value)}
                            value={filterCategory}>
                        <option value="All">Все</option>
                        {categories.map(category => (
                            <option value={category._id} key={category._id}>{category.title}</option>
                        ))}
                    </select>
                </div>
            </form>

            {admin.rights.includes('createModule') && (
                <NavLink to='create' className='navLink button greenButton'>
                    Создать модуль
                </NavLink>
            )}

            <table className='table'>
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Категория</th>
                    <th>Модуль</th>
                    <th>Подмодули</th>
                </tr>
                </thead>

                <tbody>
                {modulesFiltered.map((module, index) => (
                    <tr className='tableTr' key={module._id}>
                        <td><NavLink to={module._id}>&#9998;</NavLink></td>
                        <td>{index + 1}</td>
                        <td>{module.category.title}</td>
                        <td>{module.title}</td>
                        <td>{module.submodules.map((submodule, index) => <div>{index + 1}. {submodule.title}</div>)}</td>
                    </tr>
                ))}
                </tbody>


            </table>


        </div>
    )
}
