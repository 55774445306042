import React, {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {getSubmodulesAction} from "../../../redux/actions/submoduleAction";

export default function AllSubmodulesPage() {
    const {submodules} = useSelector(state => state.submoduleReducer)
    const {modules} = useSelector(state => state.moduleReducer)
    const [filterCategory, setFilterCategory] = useState('All')

    const dispatch = useDispatch()
    const {admin} = useSelector(state => state.adminReducer)

    useEffect(() => {
        dispatch(getSubmodulesAction())
    }, []);

    let submodulesFiltered = submodules.filter(submodule1 => {
        return true

        if (filterCategory === 'All') {
            return true
        } else {
            return !!modules.find(submodule0 => submodule0.submodules.find(submodule2 => submodule2._id === submodule1._id))
        }
    })

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <span>Управление подмодулями</span>
            </div>
            {/*<form className='filterForm'>*/}
            {/*    <div className="formFlexGroup">*/}
            {/*        <div className='formBox'>*/}
            {/*            <label htmlFor='module'>*/}
            {/*                Модуль*/}
            {/*            </label>*/}
            {/*            <select name="" id="module" onChange={e => setFilterCategory(e.target.value)}*/}
            {/*                    value={filterCategory}>*/}
            {/*                <option value="All">Все</option>*/}
            {/*                <option value="640e8aff4ea080395d7df756">CES</option>*/}
            {/*                <option value="640e8b1c4ea080395d7df757">Marlins</option>*/}
            {/*                <option value="640e8b4c4ea080395d7df758">Conv</option>*/}
            {/*            </select>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="formBox">*/}
            {/*        <button className="button greenButton">*/}
            {/*            Применить*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</form>*/}


            {admin.rights.includes('createSubmodule') && (
                <NavLink to='create' className='navLink button greenButton'>
                    Создать подмодуль
                </NavLink>
            )}

            <table className='table'>
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Название</th>
                    <th>id in</th>
                    <th>id out</th>
                </tr>
                </thead>

                <tbody>
                {submodulesFiltered.map((submodule, index) => (
                    <tr className='tableTr' key={submodule._id}>
                        <td><NavLink to={submodule._id}>&#9998;</NavLink></td>
                        <td>{index + 1}</td>
                        <td>{submodule.title}</td>
                        <td>{submodule.idIn}</td>
                        <td>{submodule.idOut}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
