import React, {useState} from 'react';
import './Login.css'
import {useDispatch, useSelector} from "react-redux";
import {loginAdminAction} from "../../redux/actions/adminActions";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()
    const {admin} = useSelector(state => state.adminReducer)
    const navigate = useNavigate();

    if(Object.keys(admin).length > 0) {
        navigate('/')
    }


    const handleLogin = (e) => {
        e.preventDefault()
        if(!login || !password) return alert('Введите логин и пароль')

        dispatch(loginAdminAction({login, password})).then(() => {

        }).catch(err => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 5000,
            })
        })
    }

    return (
        <div className='loginBlockContainer'>
            <div className="loginFormOuter">
                <form className="loginForm" onSubmit={handleLogin}>
                    <div className="loginFormTitle">Войти в аккаунт</div>
                    <div className="loginFormInputs">
                        <input type="text" placeholder='Логин' value={login} onChange={(e) => setLogin(e.target.value)}/>
                        <input type="password" placeholder='Пароль' value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <button className="button loginButton" >Войти</button>
                    </div>
                </form>
            </div>


        </div>
    );
};

export default LoginPage
