const initialState = {
    submodules: [],
    error: null
}

export default function submoduleReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SUBMODULES':
            return {
                ...state,
                submodules: action.payload
            }
        case 'SET_SUBMODULE':
            const index = state.submodules.findIndex(submodule => submodule._id === action.payload._id);
            if (index === -1) {
                return {
                    ...state,
                    submodules: [...state.submodules, action.payload]
                }
            }
            return {
                ...state,
                submodules: state.submodules.map(submodule => submodule._id === action.payload._id ? action.payload : submodule)
            }
        case 'ADD_SUBMODULE':
            return {
                ...state,
                submodules: [...state.submodules, action.payload]
            }
        case 'CREATE_SUBMODULE_FAIL':
            return {
                ...state,
                error: action.payload
            }

        case 'UPDATE_SUBMODULE':
            return {
                ...state,
                submodules: state.submodules.map(submodule => submodule._id === action.payload._id ? action.payload : submodule)
            }

        case 'DELETE_SUBMODULE':
            return {
                ...state,
                submodules: state.submodules.filter(submodule => submodule._id !== action.payload._id)
            }
        default:
            return state;
    }
}