import {useState} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {createQuestionAction} from "../../../redux/actions/questionActions";
import {toast} from "react-toastify";

const CreateQuestionPage = () => {
    const [answersCount, setAnswersCount] = useState(4)
    const [answers, setAnswers] = useState([])
    const [correctAnswersIndexes, setCorrectAnswersIndexes] = useState([0])
    const [formId, setFormId] = useState('')
    const [question, setQuestion] = useState('')
    const [correctAnswerText, setCorrectAnswerText] = useState('')
    const [questionType, setQuestionType] = useState('text')
    const [media, setMedia] = useState(null)


    const {id} = useParams();
    const dispatch = useDispatch()
    const {submodules} = useSelector(state => state.submoduleReducer)
    const {questions} = useSelector(state => state.questionReducer)

    const submoduleData = submodules.find(submodule => submodule._id === id)

    const handleAnswersChange = (e) => {
        const answersCopy = [...answers]
        const index = e.target.id.split('_')[1]
        answersCopy[index] = e.target.value
        setAnswers(answersCopy)
    }

    const addAnswerHandler = (e) => {
        e.preventDefault()
        setAnswersCount(prev => prev + 1)
    }

    const removeAnswerHandler = (e) => {
        e.preventDefault()
        if (answersCount > 2) setAnswersCount(prev => prev - 1)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const ifIdExists = questions.find(question => question.id === +formId)

        if(!formId || isNaN(+formId) || formId < submoduleData.idIn || formId > submoduleData.idOut) {
            toast.error('Введите правильный id', {
                position: "top-right",
                autoClose: 3000,
            })
            return
        }
        if (ifIdExists) {
            toast.error('Вопрос с таким id уже существует', {
                position: "top-right",
                autoClose: 3000,
            })
            return
        }

        if (!question || answers.length < 2 || !correctAnswerText || correctAnswersIndexes.length === 0) {
            toast.error('Заполните все поля', {
                position: "top-right",
                autoClose: 3000,
            })
            return
        }

        if(answers[correctAnswersIndexes[0]] !== correctAnswerText) {
            toast.error('Текст верного ответа должен совпадать с текстом ответа', {
                position: "top-right",
                autoClose: 3000,
            })
            return
        }

        const formData = new FormData()
        formData.append('id', formId)
        formData.append('question', question)
        formData.append('answers', JSON.stringify([...answers].splice(0, answersCount)))
        formData.append('correctAnswersIndexes',  JSON.stringify(correctAnswersIndexes))
        formData.append('correctAnswer', correctAnswerText)
        formData.append('submodule', id)
        formData.append('type', questionType)

        if(['image', 'audio'].includes(questionType)) {
            formData.append('media', media)
        }

        dispatch(createQuestionAction(formData)).then(() => {
            toast.success('Вопрос успешно создан', {
                position: "top-right",
                autoClose: 3000,
            })

            setAnswers([])
            setCorrectAnswersIndexes([0])
            setFormId('')
            setQuestion('')
            setCorrectAnswerText('')
            setQuestionType('text')
            setMedia(null)
            // navigate(`/submodules/${id}/questions`)
        }).catch((err) => {
            toast.error('Ошибка при создании вопроса: ' + err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }

    const handleCorrectAnswerChange = (e) => {
        if(correctAnswersIndexes.includes(+e.target.value)) {
            setCorrectAnswersIndexes(prev => prev.filter(index => index !== +e.target.value))
        } else {
            setCorrectAnswersIndexes(prev => [...prev, +e.target.value])
        }
    }

    const questionsLink = window.location.href.split('/').slice(0, -1).join('/')
    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/submodules'>Управление подмодулями</NavLink>
                <span>&rarr;</span>
                <NavLink to={`/submodules/${submoduleData?._id}`}>{submoduleData?.title}</NavLink>
                <span>&rarr;</span>
                <NavLink to={questionsLink}>Вопросы</NavLink>
                <span>&rarr;</span>
                <span>Создать</span>
            </div>


            <form className='filterForm' onSubmit={handleSubmit}>
                <div className='formBox'>
                    <label htmlFor="id">
                        id
                    </label>
                    <input type="text" id="id" value={formId} onChange={(e) => setFormId(e.target.value)}/>
                </div>

                <div className='formBox'>
                    <label htmlFor="question">
                        Вопрос
                    </label>
                    <input type="text" id="question" value={question} onChange={(e) => setQuestion(e.target.value)}/>
                </div>

                {Array(answersCount).fill(0).map((_, index) => (
                    <div className='formBox' key={index}>
                        <label htmlFor={`answer_${index}`}>
                            Ответ {index + 1}
                        </label>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <input type="text" value={answers[index] || ''} onChange={handleAnswersChange} id={`answer_${index}`}/>
                            <input type="checkbox"
                                   checked={correctAnswersIndexes.includes(index)}
                                   value={index}
                                   onChange={handleCorrectAnswerChange}
                                   name='rightAnswer'/>
                        </div>
                    </div>
                ))}
                <div className="formBox formBoxButtons">
                    <button onClick={addAnswerHandler}>+</button>
                    <button onClick={removeAnswerHandler}>-</button>

                </div>

                <div className='formBox'>
                    <label htmlFor="rightAnswerText">
                        Текст верного ответа
                    </label>
                    <input type="text" id="rightAnswerText" value={correctAnswerText}
                           onChange={(e) => setCorrectAnswerText(e.target.value)}/>
                </div>

                <div className="formBox">
                    <label>
                        Тип вопроса
                    </label>
                    {['text', 'image', 'audio'].map(type => (
                        <div className="radioBox" key={type}>
                            <input id={type} type="radio"
                                   name='type' checked={questionType === type}
                                   onChange={() => setQuestionType(type)}/>

                            <label htmlFor={type}>{type}</label>
                        </div>
                    ))}

                    {questionType === 'image' && (
                        <div className="formBox">
                            <label htmlFor="image">
                                Изображение
                            </label>
                            <input type="file" accept='image/*' id="image"  onChange={(e) => setMedia(e.target.files[0])}/>
                        </div>

                    )}

                    {questionType === 'audio' && (
                        <div className="formBox">
                            <label htmlFor="audio">
                                Аудио
                            </label>
                            <input type="file" accept='audio/*' id="audio" onChange={(e) => setMedia(e.target.files[0])}/>
                        </div>
                    )}
                </div>

                <div className='formBox'>
                    <button className="button greenButton">
                        Создать
                    </button>
                </div>


            </form>
        </div>
    );
};

export default CreateQuestionPage
