import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom'
import './SubmoduleQuestions.css'
import {useDispatch, useSelector} from "react-redux";
import {deleteQuestionAction, getQuestionsAction} from "../../../redux/actions/questionActions";
import {importQuestionsRequest} from "../../../http/questionRequests";
import Preloader from "../../../components/Preloader/Preloader";
import {toast} from "react-toastify";

const SubmoduleQuestions = () => {
    const {id} = useParams();

    const [file, setFile] = useState(null)
    const [zip, setZip] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const {admin} = useSelector(state => state.adminReducer)
    const {submodules} = useSelector(state => state.submoduleReducer)
    const {questions} = useSelector(state => state.questionReducer)
    const submoduleData = submodules.find(submodule => submodule._id === id)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getQuestionsAction(id))
    }, []);

    const handleLoad = (e) => {
        e.preventDefault()

        if (!file) return alert('Выберите файл')
        setIsLoading(true)
        const formData = new FormData();
        formData.append('csv', file);
        formData.append('images', zip);
        importQuestionsRequest(id, formData).then(r => {
            window.location.reload()
        }).catch(e => {
            alert(e.response.data.error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const deleteQuestion = (e, id) => {
        e.preventDefault()
        if (!window.confirm('Вы уверены?')) {
            return
        }

        dispatch(deleteQuestionAction(id)).catch(err => {
            console.log(err.message)
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }
    return (
        <div>
            {isLoading && <Preloader show={isLoading}/>}
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/submodules'>Управление подмодулями</NavLink>
                <span>&rarr;</span>
                <NavLink to={`/submodules/${submoduleData?._id}`}>{submoduleData?.title}</NavLink>
                <span>&rarr;</span>
                <span>Вопросы</span>
            </div>
            {admin.rights.includes('createQuestion') && (
                <>
                    <div>
                        <NavLink to='create' className='button yellowButton'>Добавить вопрос</NavLink>
                    </div>
                    <div>
                        <br/>
                        <form onSubmit={handleLoad}>
                            <div>

                                <input type="file" accept='text/csv' id="csv"
                                       onChange={(e) => setFile(e.target.files[0])}/>
                                <label htmlFor="csv">Select csv</label>
                            </div>
                            <div>
                                <input type="file" id="images" accept='application/zip,application/x-zip-compressed'
                                       onChange={(e) => setZip(e.target.files[0])}/>
                                <label htmlFor="images">Select images(zip format only)</label>
                            </div>
                            <button className='button greenButton'>Загрузить</button>
                        </form>
                    </div>
                </>
            )}
            <table className='questions_table'>
                <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>id</th>
                    <th>Вопрос</th>
                    <th>Ответ 1</th>
                    <th>Ответ 2</th>
                    <th>Ответ 3</th>
                    <th>Ответ 4</th>
                    <th>Ответ 5</th>
                    <th>Верный</th>
                    <th>Верный текст</th>
                    <th>Тип</th>
                    <th>Дата доб.</th>
                    <th>Дата корр.</th>
                    <th>№</th>
                </tr>
                </thead>

                <tbody>
                {questions.map((question, index) => (
                    <tr key={question._id}>
                        <td onClick={(e) => deleteQuestion(e, question._id)}>&#128465;</td>
                        <td><NavLink to={question._id}>&#9998;</NavLink></td>
                        <td>#{question.id}</td>
                        <td title={question.question}>{question.question.length > 5 ? question.question.slice(0, 5) + '...' : question.question}</td>
                        <td>{question.answers[0]}</td>
                        <td>{question.answers[1]}</td>
                        <td>{question.answers[2]}</td>
                        <td>{question.answers[3]}</td>
                        <td>{question.answers[4]}</td>
                        <td>{question.correctAnswersIndexes.map(item => item + 1).join(', ')}</td>
                        <td>{question.correctAnswer}</td>
                        <td>{question.type}</td>
                        <td>{new Date(question.createdAt).toLocaleString()}</td>
                        <td>{new Date(question.updatedAt).toLocaleString()}</td>
                        <td>{index + 1}</td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
    );
};

export default SubmoduleQuestions