import React, {useState} from 'react';
import AdminRights from "../AdminRights/AdminRights";

const EditAdminForm = ({data, onSubmit}) => {
    const [login, setLogin] = useState(data.login || '')
    const [password, setPassword] = useState('')
    const [rights, setRights] = useState(data.rights || [])

    const editUserHandler = (e) => {
        e.preventDefault()
        if(!login) return alert('Введите логин')
        onSubmit({login, password, rights})
    }

    const handleRightsChange = (e) => {
        if (e.target.checked) {
            setRights([...rights, e.target.value])
        } else {
            setRights(rights.filter(right => right !== e.target.value))
        }
    }

    return (
        <form className='filterForm' onSubmit={editUserHandler}>
            <div className="formFlexGroup column">
                <div className='formBox'>
                    <label htmlFor='category'>
                        Логин
                    </label>
                    <input type="text" id="module" value={login}
                           onChange={(e) => setLogin(e.target.value)}/>
                </div>
                <div className='formBox'>
                    <label htmlFor='category'>
                        Пароль
                    </label>
                    <input type="text" id="module" value={password}
                           onChange={(e) => setPassword(e.target.value)}/>
                </div>

                <div className="formFlexGroup column">
                    <div className='formBox'>
                        <label htmlFor='module' style={{fontSize: "18px", fontWeight: '600'}}>
                            Права
                        </label>
                        <AdminRights handleRightsChange={handleRightsChange} rights={rights} />
                    </div>
                </div>
            </div>

            <div className="formBox">
                <button className="button greenButton">
                    Обновить
                </button>
            </div>

        </form>
    );
};

export default EditAdminForm
