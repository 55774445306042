import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {createAdminAction, getAdminsAction} from "../../redux/actions/adminActions";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";

export default function HomePage() {



    return (
        <div>
            <div className='breadcrumbs'>
                <span>Главная</span>
            </div>





        </div>
    )
}
