import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAssembliesAction} from "../../../redux/actions/assemblyActions";

const AllAssembliesPage = () => {
    const {assemblies} = useSelector(state => state.assemblyReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAssembliesAction())
    }, [])

    console.log(assemblies)


    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <span>Сборки</span>
            </div>

            <NavLink to='create' className='navLink button greenButton'>
                Создать сборку
            </NavLink>


            <table className='table'>
                <thead>
                <tr>
                    <th></th>
                    <th>Название</th>
                    <th>Версия</th>
                    <th>Модули</th>
                    <th>Дата создания</th>
                    <th>Дата ред.</th>
                </tr>
                </thead>

                <tbody>
                {assemblies.map(assembly => (
                    <tr className='tableTr' key={assembly._id}>
                        <td><NavLink to={assembly._id}>&#9998;</NavLink></td>
                        <td>{assembly.title}</td>
                        <td>{assembly.version}</td>
                        <td>{assembly.modules.map(module => module.title).join(', ')}</td>
                        <td>{new Date(assembly.createdAt).toLocaleString()}</td>
                        <td>{new Date(assembly.updatedAt).toLocaleString()}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AllAssembliesPage