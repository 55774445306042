import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {createModuleAction} from "../../../redux/actions/moduleActions";
import {toast} from "react-toastify";

const CreateModulePage = () => {
    const [category, setCategory] = useState('640e8aff4ea080395d7df756')
    const [title, setTitle] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate();


    const createModule = async (e) => {
        e.preventDefault()
        if (!title) return alert('Введите название модуля')

        dispatch(createModuleAction({category, title})).then(() => {
            toast.success('Модуль успешно создан', {
                position: "top-right",
                autoClose: 3000
            })
            setTitle('')
            setCategory('640e8aff4ea080395d7df756')
            // navigate('/modules')
        }).catch(err => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }


    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/modules'>Управление модулями</NavLink>
                <span>&rarr;</span>
                <span>Создать</span>
            </div>

            <form className='filterForm' onSubmit={createModule}>
                <div className="formFlexGroup column">
                    <div className='formBox'>
                        <label htmlFor='category'>
                            Категория
                        </label>
                        <select name="" value={category} id="module" onChange={(e) => setCategory(e.target.value)}>
                            <option value="640e8aff4ea080395d7df756">Deck</option>
                            <option value="640e8b1c4ea080395d7df757">no</option>
                            <option value="640e8b4c4ea080395d7df758">Deck&Engine</option>
                            <option value="654ff821e7285b5e171c705b">Engine</option>
                            <option value="654ff83ee7285b5e171c705c">Electrical</option>
                        </select>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='module'>
                            Название модуля
                        </label>
                        <input type="text" id="module" value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>
                </div>

                <div className="formBox">
                    <button className="button greenButton">
                        Создать
                    </button>
                </div>

            </form>
        </div>
    );
};

export default CreateModulePage
