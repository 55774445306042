import {ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getModulesAction} from "./redux/actions/moduleActions";
import {getSubmodulesAction} from "./redux/actions/submoduleAction";
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import Header from "./components/Header/Header";

import HomePage from "./pages/HomePage/HomePage";
import SearchPage from "./pages/SearchPage/SearchPage";

import AllModulesPage from "./pages/Modules/AllModulesPage/AllModulesPage";
import CreateModulePage from "./pages/Modules/CreateModulePage/CreateModulePage";
import ModulePage from "./pages/Modules/EditModulePage/EditModulePage";

import AllSubmodulesPage from "./pages/Submodules/AllSubmodulesPage/AllSubmodulesPage";
import CreateSubmodulePage from "./pages/Submodules/CreateSubmodulePage/CreateSubmodulePage";
import SubmodulePage from "./pages/Submodules/EditSubmodulePage/EditSubmodulePage";

import SubmoduleQuestions from "./pages/Questions/SubmoduleQuestions/SubmoduleQuestions";
import CreateQuestionPage from "./pages/Questions/CreateQuestionPage/CreateQuestionPage";
import EditQuestionPage from "./pages/Questions/EditQuestionPage/EditQuestionPage";

import AssembliesPage from "./pages/Assembly/AllAssembliesPage/AllAssembliesPage";
import CreateAssemblyPage from "./pages/Assembly/CreateAssemblyPage/CreateAssemblyPage";
import EditAssemblyPage from "./pages/Assembly/EditAssemblyPage/EditAssemblyPage";
import AdminPage from "./pages/SuperAdmin/AdminPage/AdminPage";
import CreateAdminPage from "./pages/SuperAdmin/CreateAdminPage/CreateAdminPage";
import EditAdminPage from "./pages/SuperAdmin/EditAdminPage/EditAdminPage";
import LoginPage from "./pages/Login/LoginPage";
import {checkAuthAction} from "./redux/actions/adminActions";
import ProtectedRoute from "./components/ProtectedRoute";


const token = localStorage.getItem('token');

function App() {
    const [loading, setLoading] = useState(!!token)
    const dispatch = useDispatch()

    const {admin} = useSelector(state => state.adminReducer)

    useEffect(() => {
        if (token) {
            dispatch(checkAuthAction()).finally(() => {
                setLoading(false)
                dispatch(getModulesAction())
                dispatch(getSubmodulesAction())
            })
        }
    }, []);

    console.log(admin)

    return (
        <div className="App" style={{backgroundImage: 'url(/images/img.jpg)'}}>
            <div className="wrapper">
                <div className="container">
                    <ToastContainer/>

                    {Object.keys(admin).length > 0 && <Header/>}
                    <Routes>
                        <Route path="/login" element={<LoginPage/>}/>

                        <Route path='' element={<ProtectedRoute admin={admin} isLoading={loading}/>}>
                            <Route path="/" element={<HomePage/>}/>

                            <Route path="/admin">
                                <Route path="" element={<AdminPage/>}/>
                                <Route path="create" element={<CreateAdminPage/>}/>
                                <Route path=":id" element={<EditAdminPage/>}/>
                            </Route>

                            <Route path="/modules" element={<ProtectedRoute admin={admin} isLoading={loading}/>}>
                                <Route path="" element={<AllModulesPage/>}/>
                                <Route path="create" element={<CreateModulePage/>}/>
                                <Route path=":id" element={<ModulePage/>}/>
                            </Route>

                            <Route path="/submodules" element={<ProtectedRoute admin={admin} isLoading={loading}/>}>
                                <Route path="" element={<AllSubmodulesPage/>}/>
                                <Route path="create" element={<CreateSubmodulePage/>}/>
                                <Route path=":id">
                                    <Route path="" element={<SubmodulePage/>}/>
                                    <Route path="questions">
                                        <Route path="" element={<SubmoduleQuestions/>}/>
                                        <Route path=":questionId" element={<EditQuestionPage/>}/>
                                        <Route path="create" element={<CreateQuestionPage/>}/>
                                    </Route>
                                </Route>
                            </Route>

                            <Route path="/assemblies" element={<ProtectedRoute admin={admin} isLoading={loading}/>}>
                                <Route path="" element={<AssembliesPage/>}/>
                                <Route path=":id" element={<EditAssemblyPage/>}/>
                                <Route path="create" element={<CreateAssemblyPage/>}/>
                            </Route>

                            <Route path="/search" element={<SearchPage/>}/>
                        </Route>

                        <Route path='*' element={<LoginPage/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
