import api from './api';

export const getQuestionsRequest = async (submoduleId) => api.get(`/questions?submodule=${submoduleId}`);

export const getQuestionRequest = async (id) => api.get(`/questions/${id}`);

export const createQuestionRequest = async (question) => api.post(`/questions`, question, {headers: {'Content-Type': 'multipart/form-data'}} );

export const updateQuestionRequest = async (id, question) => api.put(`/questions/${id}`, question);

export const deleteQuestionRequest = async (id) => api.delete(`/questions/${id}`);

export const importQuestionsRequest = (submoduleId, data) => api.post(`/questions/import?submoduleId=${submoduleId}`, data);