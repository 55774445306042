import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {getModulesAction} from "../../../redux/actions/moduleActions";
import {createAssemblyAction} from "../../../redux/actions/assemblyActions";
import {toast} from "react-toastify";

const CreateAssemblyPage = () => {
    const [formTitle, setFormTitle] = useState('')
    const [formVersion, setFormVersion] = useState('')
    const [passingPercent, setPassingPercent] = useState('')
    const [formModules, setFormModules] = useState([])

    const {modules} = useSelector(state => state.moduleReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if(modules.length === 0) {
            dispatch(getModulesAction())
        }

    }, []);


    const createAssembly = (e) => {
        e.preventDefault()

        if(!formTitle) return alert('Введите название сборки')
        if(!formVersion) return alert('Введите версию сборки')
        if(formModules.length === 0) return alert('Выберите модули для сборки')
        if(isNaN(+passingPercent)) return alert('Проходной бал должен быть числом')

        const data = {
            title: formTitle,
            version: formVersion,
            modules: formModules.map(module => module.value),
            passingPercent: +passingPercent
        }

        dispatch(createAssemblyAction(data)).then(() => {
            toast.success('Сборка успешно создана', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate('/assemblies')
        }).catch(err => {
            toast.error('Ошибка при создании сборки: ' + err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/assemblies'>Сборки</NavLink>
                <span>&rarr;</span>
                <span>Создать</span>
            </div>

            <form className='filterForm' onSubmit={createAssembly}>
                <div className="formFlexGroup column">
                    <div className='formBox'>
                        <label htmlFor='category'>
                            Название сборки
                        </label>
                        <input type="text" id="module" value={formTitle} onChange={(e) => setFormTitle(e.target.value)}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='module'>
                            Версия
                        </label>
                        <input type="text" id="module" value={formVersion} onChange={(e) => setFormVersion(e.target.value)}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='passingScore'>
                            Проходной процент
                        </label>
                        <input type="text" id="passingScore" value={passingPercent} onChange={(e) => setPassingPercent(e.target.value)}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='module'>
                            Модули
                        </label>
                        <Select onChange={(e) => setFormModules(e)}
                                isMulti={true}
                                value={formModules}
                                options={modules.map(module => ({
                                    value: module._id,
                                    label: module.title
                                }))}
                        />
                    </div>
                </div>

                <div className="formBox">
                    <button className="button greenButton">
                        Создать
                    </button>
                </div>

            </form>

        </div>
    );
};

export default CreateAssemblyPage