const initialState = {
    questions: [],
    error: null
}

export default function questionReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_QUESTIONS':
            return {
                ...state,
                questions: action.payload
            }
        case 'SET_QUESTION':
            const index = state.questions.findIndex(question => question._id === action.payload._id);
            if (index === -1) {
                return {
                    ...state,
                    questions: [...state.questions, action.payload]
                }
            }
            return {
                ...state,
                questions: state.questions.map(question => question._id === action.payload._id ? action.payload : question)
            }
        case 'ADD_QUESTION':
            return {
                ...state,
                questions: [...state.questions, action.payload]
            }
        case 'CREATE_QUESTION_FAIL':
            return {
                ...state,
                error: action.payload
            }
        case 'UPDATE_QUESTION':
            return {
                ...state,
                questions: state.questions.map(question => question._id === action.payload._id ? action.payload : question)
            }
        case 'DELETE_QUESTION':
            return {
                ...state,
                questions: state.questions.filter(question => question._id !== action.payload._id)
            }
        default:
            return state;
    }
}