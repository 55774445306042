import {
    createSubmoduleRequest, deleteSubmoduleRequest,
    getSubmoduleRequest,
    getSubmodulesRequest,
    updateSubmoduleRequest
} from "../../http/submoduleRequests";

export const getSubmodulesAction = () => async (dispatch) => {
    try {
        const {data} = await getSubmodulesRequest();
        dispatch({type: 'SET_SUBMODULES', payload: data});
    } catch (error) {
        dispatch({type: 'GET_SUBMODULES_FAIL', payload: error.message});
    }
}

export const getSubmoduleAction = (id) => async (dispatch) => {
    try {
        const {data} = await getSubmoduleRequest(id);
        dispatch({type: 'SET_SUBMODULE', payload: data});
    } catch (error) {
        dispatch({type: 'GET_SUBMODULE_FAIL', payload: error.message});
    }
}

export const createSubmoduleAction = (submodule) => async (dispatch) => {
    try {
        const {data} = await createSubmoduleRequest(submodule);
        dispatch({type: 'ADD_SUBMODULE', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const updateSubmoduleAction = (id, submodule) => async (dispatch) => {
    try {
        const {data} = await updateSubmoduleRequest(id, submodule);
        dispatch({type: 'UPDATE_SUBMODULE', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const deleteSubmoduleAction = (id) => async (dispatch) => {
    try {
        const {data} = await deleteSubmoduleRequest(id);
        dispatch({type: 'DELETE_SUBMODULE', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}