import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deleteModuleAction, getModuleAction, updateModuleAction} from "../../../redux/actions/moduleActions";
import Select from "react-select";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const {useParams, NavLink} = require("react-router-dom");

const EditModulePage = () => {
    const {id} = useParams();
    const {modules} = useSelector(state => state.moduleReducer)
    const {submodules} = useSelector(state => state.submoduleReducer)
    const [formData, setFormData] = useState({
        title: '',
        submodules: [],
    })
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getModuleAction(id))
    }, []);

    useEffect(() => {
        const moduleData = modules.find(module => module._id === id)
        if (moduleData) {

            setFormData({
                title: moduleData.title,
                submodules: moduleData.submodules.map(submodule => ({
                    value: submodule._id,
                    label: submodule.title
                }))
            })
        }
    }, [modules]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const moduleData = modules.find(module => module._id === id)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!formData.title) return alert('Введите название модуля')

        dispatch(updateModuleAction(id, {
            ...formData,
            submodules: formData.submodules.map(submodule => submodule.value)
        })).then(() => {
            toast.success('Модуль успешно обновлен', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate('/modules')
        }).catch((err) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }

    const handleDelete = (e) => {
        e.preventDefault()

        const confirm = window.confirm('Вы уверены, что хотите удалить модуль?')
        if (!confirm) return

        dispatch(deleteModuleAction(id)).then(() => {
            toast.success('Модуль успешно удален', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate('/modules')
        }).catch((err) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/modules'>Управление модулями</NavLink>
                <span>&rarr;</span>
                <span>{moduleData?.title}</span>
            </div>


            <form className='filterForm' onSubmit={handleSubmit}>
                <div className="formFlexGroup column">

                    <div className='formBox'>
                        <label htmlFor='module'>
                            Имя модуля
                        </label>
                        <input type="text" id="module" name='title' value={formData.title} onChange={handleChange}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='add_submodules'>
                            Добавить подмодули:
                        </label>

                        <Select onChange={(e) => setFormData({...formData, submodules: e})}
                                isMulti={true}
                                defaultValue={formData.submodules}
                                value={formData.submodules}
                                options={submodules.map(submodule => ({
                                    value: submodule._id,
                                    label: submodule.title
                                }))}
                        />
                    </div>

                    <button className="button greenButton">
                        Сохранить
                    </button>
                </div>
            </form>
            <button className="button redButton" onClick={handleDelete}>
                Удалить
            </button>
        </div>
    );
};

export default EditModulePage