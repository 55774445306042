const initialState = {
    assemblies: [],
    assembly: {}
}

export const assemblyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ASSEMBLIES':
            return {...state, assemblies: action.payload};
        case 'UPDATE_ASSEMBLY':
            return {...state, assemblies: state.assemblies.map(assembly => assembly._id === action.payload._id ? action.payload : assembly)};
        case 'ADD_ASSEMBLY':
            return {...state, assemblies: [...state.assemblies, action.payload]};
        default:
            return state;
    }
}

export default assemblyReducer;