import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {deleteSubmoduleAction, getSubmoduleAction, updateSubmoduleAction} from "../../../redux/actions/submoduleAction";
import {toast} from 'react-toastify';

const EditSubmodulePage = () => {

    const {id} = useParams();
    const {submodules} = useSelector(state => state.submoduleReducer)
    const [formData, setFormData] = useState({
        title: '',
        idIn: '',
        idOut: '',
    })

    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSubmoduleAction(id))
    }, []);

    useEffect(() => {
        const submoduleData = submodules.find(submodule => submodule._id === id)
        if (submoduleData) {

            setFormData({
                title: submoduleData.title,
                idIn: submoduleData.idIn,
                idOut: submoduleData.idOut,
            })
        }
    }, [submodules]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!formData.title) return alert('Введите название модуля')
        if (formData.idIn.length === 0 || isNaN(formData.idIn)) return alert('Введите правильный id in')
        if (formData.idOut.length === 0 || isNaN(formData.idIn)) return alert('Введите правильный id out')

        dispatch(updateSubmoduleAction(id, formData)).then(() => {
            toast.success('Подмодуль успешно обновлен', {
                position: "top-right",
                autoClose: 3000
            })
            navigate('/submodules')
        }).catch((err) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000
            })
        })
    }

    const handleDelete = (e) => {
        e.preventDefault()

        const confirm = window.confirm('Вы уверены, что хотите удалить подмодуль?')
        if (!confirm) return

        dispatch(deleteSubmoduleAction(id)).then(() => {
            toast.success('Подмодуль успешно удален', {
                position: "top-right",
                autoClose: 3000
            })
            navigate('/submodules')
        }).catch((err) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000
            })
        })
    }
    const submoduleData = submodules.find(submodule => submodule._id === id)

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/submodules'>Управление подмодулями</NavLink>
                <span>&rarr;</span>
                <span>{submoduleData?.title}</span>
            </div>

            <form className='filterForm' onSubmit={handleSubmit}>
                <div className="formFlexGroup column">

                    <div className='formBox'>
                        <label htmlFor='module'>
                            Имя подмодуля
                        </label>
                        <input type="text" id="module" name='title' onChange={handleChange} value={formData.title}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='id_in'>
                            id in
                        </label>
                        <input type="text" id="id_in" name='idIn' onChange={handleChange} value={formData.idIn}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='id_out'>
                            id out
                        </label>
                        <input type="text" id="id_out" name='idOut' onChange={handleChange} value={formData.idOut}/>
                    </div>
                    <div className="formBox">
                        <button className="button greenButton ">
                            Обновить
                        </button>
                    </div>
                </div>
            </form>
            <button className="button redButton" onClick={handleDelete}>
                Удалить
            </button>
            <br/>
            <NavLink to='questions' className='navLink button yellowButton'>Посмотреть вопросы</NavLink>
        </div>
    );
};

export default EditSubmodulePage