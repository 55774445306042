import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAdminsAction} from "../../../redux/actions/adminActions";
import {NavLink, useNavigate} from "react-router-dom";

const AdminPage = () => {
    const {admins} = useSelector(state => state.adminReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAdminsAction())
    }, []);

    const role = useSelector(state => state.adminReducer.admin.role)
    if(role !== 'superadmin') {
        navigate('/')
        return null
    }

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <span>Админка</span>
            </div>

            <NavLink to='create' className='navLink button greenButton'>
                Создать админа
            </NavLink>

            <table className='table'>
                <thead>
                <tr>
                    <th></th>
                    <th>Логин</th>
                    <th>Дата создания</th>
                    <th>Дата ред.</th>
                </tr>
                </thead>

                <tbody>
                {admins.map(assembly => (
                    <tr className='tableTr' key={assembly._id}>
                        <td><NavLink to={assembly._id}>&#9998;</NavLink></td>
                        <td>{assembly.login}</td>
                        <td>{new Date(assembly.createdAt).toLocaleString()}</td>
                        <td>{new Date(assembly.updatedAt).toLocaleString()}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminPage
