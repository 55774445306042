import React from 'react';
import {Navigate, Outlet} from "react-router-dom";

const ProtectedRoute = ({admin,isLoading, redirectPath = '/login' }) => {
    const isLoggedIn = admin && Object.keys(admin).length > 0
    if(isLoggedIn)
        return <Outlet />

    if(!isLoading)
        return <Navigate to={redirectPath} />
};

export default ProtectedRoute