import {
    createModuleRequest,
    deleteModuleRequest,
    getModuleRequest,
    getModulesRequest,
    updateModuleRequest
} from "../../http/moduleRequests";

export const getModulesAction = () => async (dispatch) => {
    try {
        const {data} = await getModulesRequest();
        dispatch({type: 'SET_MODULES', payload: data});
    } catch (error) {
        dispatch({type: 'GET_MODULES_FAIL', payload: error.message});
    }
}

export const getModuleAction = (id) => async (dispatch) => {
    try {
        const {data} = await getModuleRequest(id);
        dispatch({type: 'SET_MODULE', payload: data});
    } catch (error) {
        dispatch({type: 'GET_MODULE_FAIL', payload: error.message});
    }
}

export const updateModuleAction = (id, module) => async (dispatch) => {
    try {
        const {data} = await updateModuleRequest(id, module);
        dispatch({type: 'UPDATE_MODULE', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const createModuleAction = (module) => async (dispatch) => {
    try {
        const {data} = await createModuleRequest(module);
        dispatch({type: 'ADD_MODULE', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const deleteModuleAction = (id) => async (dispatch) => {
    try {
        const {data} = await deleteModuleRequest(id);
        dispatch({type: 'DELETE_MODULE', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}