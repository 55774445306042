import api from './api';

export const createAdminRequest = async (admin) => api.post(`/admins`, admin);

export const loginAdminRequest = async (admin) => api.post(`/admins/login`, admin);

export const checkAuthRequest = async () => api.post(`/admins/checkAuth`);

export const getAdminsRequest = async () => api.get(`/admins`);

export const editAdminRequest = async (id, admin) => api.put(`/admins/${id}`, admin);