import {
    createQuestionRequest,
    deleteQuestionRequest,
    getQuestionRequest, getQuestionsRequest,
    updateQuestionRequest
} from "../../http/questionRequests";

export const getQuestionsAction = (submoduleId) => async (dispatch) => {
    try {
        const {data} = await getQuestionsRequest(submoduleId);
        dispatch({type: 'SET_QUESTIONS', payload: data});
    } catch (error) {
       alert(error.response.data.error);
    }
}

export const getQuestionAction = (id) => async (dispatch) => {
    try {
        const {data} = await getQuestionRequest(id);
        dispatch({type: 'SET_QUESTION', payload: data});
    } catch (error) {
        alert(error.response.data.error);
    }
}

export const createQuestionAction = (question) => async (dispatch) => {
    try {
        const {data} = await createQuestionRequest(question);
        dispatch({type: 'ADD_QUESTION', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const updateQuestionAction = (id, question) => async (dispatch) => {
    try {
        const {data} = await updateQuestionRequest(id, question);
        dispatch({type: 'UPDATE_QUESTION', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const deleteQuestionAction = (id) => async (dispatch) => {
    try {
        const {data} = await deleteQuestionRequest(id);
        dispatch({type: 'DELETE_QUESTION', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}