import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createAdminAction} from "../../../redux/actions/adminActions";
import {toast} from "react-toastify";
import {NavLink, useNavigate} from "react-router-dom";
import AdminRights from "../../../components/AdminRights/AdminRights";

const CreateAdminPage = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [rights, setRights] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const role = useSelector(state => state.adminReducer.admin.role)
    if(role !== 'superadmin') {
        navigate('/')
        return null
    }

    const handleRightsChange = (e) => {
        if (e.target.checked) {
            setRights([...rights, e.target.value])
        } else {
            setRights(rights.filter(right => right !== e.target.value))
        }
    }

    const createUserHandler = (e) => {
        e.preventDefault()
        if(!login || !password) return alert('Введите логин и пароль')

        dispatch(createAdminAction({login, password, rights})).then(() => {
            toast.success('Администратор успешно добавлен', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate('/admin')
        }).catch(err => {
            toast.error('Ошибка при добавлении администратора: ' + err.message, {
                position: "top-right",
                autoClose: 5000,
            })
        })
    }


    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/admin'>Админка</NavLink>
                <span>&rarr;</span>
                <span>Создать админа</span>
            </div>

            <form className='filterForm' onSubmit={createUserHandler}>
                <div className="formFlexGroup column">
                    <div className='formBox'>
                        <label htmlFor='category'>
                            Логин
                        </label>
                        <input type="text" id="module" value={login}
                               onChange={(e) => setLogin(e.target.value)}/>
                    </div>
                    <div className="formFlexGroup column">
                        <div className='formBox'>
                            <label htmlFor='module'>
                                Пароль
                            </label>
                            <input type="text" id="module" value={password}
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    </div>

                    <div className="formFlexGroup column">
                        <div className='formBox'>
                            <label htmlFor='module' style={{fontSize: "18px", fontWeight: '600'}}>
                                Права
                            </label>
                            <AdminRights handleRightsChange={handleRightsChange} rights={rights}/>
                        </div>
                    </div>
                </div>

                <div className="formBox">
                    <button className="button greenButton">
                        Создать
                    </button>
                </div>

            </form>
        </div>
    );
};

export default CreateAdminPage
