import React, {useState} from 'react';
import Select from "react-select";

const EditAssemblyForm = ({data, onSubmit, modules}) => {
    const [formTitle, setFormTitle] = useState(data.title || '')
    const [formVersion, setFormVersion] = useState(data.version || '')
    const [passingPercent, setPassingPercent] = useState(data.passingPercent || '')
    const [formModules, setFormModules] = useState(data.modules.map(module => ({
        value: module._id,
        label: module.title
    })))


    const editAssembly = (e) => {
        e.preventDefault()
        if (!formTitle) return alert('Введите название сборки')
        if(!formVersion) return alert('Введите версию сборки')
        if(formModules.length === 0) return alert('Выберите модули для сборки')
        if(isNaN(+passingPercent)) return alert('Проходной бал должен быть числом')

        if(data.version !== formVersion) {
            const isConfirm = window.confirm('Вы уверены, что хотите изменить версию сборки? Все пользователи получат обновление?')
            if(!isConfirm) return alert('Отменено пользователем')
        }

        const formData = {
            title: formTitle,
            version: formVersion,
            modules: formModules.map(module => module.value),
            passingPercent: +passingPercent
        }
        onSubmit(formData)
    }
    return (
        <div>
            <form className='filterForm' onSubmit={editAssembly}>
                <div className="formFlexGroup column">
                    <div className='formBox'>
                        <label htmlFor='category'>
                            Название сборки
                        </label>
                        <input type="text" id="module" value={formTitle} onChange={(e) => setFormTitle(e.target.value)}/>
                    </div>
                    <div className="formFlexGroup column">
                        <div className='formBox'>
                            <label htmlFor='module'>
                                Версия
                            </label>
                            <input type="text" id="module" value={formVersion} onChange={(e) => setFormVersion(e.target.value)}/>
                        </div>
                    </div>
                    <div className="formFlexGroup column">
                        <div className='formBox'>
                            <label htmlFor='module'>
                                Проходной процент
                            </label>
                            <input type="text" id="module" value={passingPercent} onChange={(e) => setPassingPercent(e.target.value)}/>
                        </div>
                    </div>
                    <div className='formBox'>
                        <label htmlFor='module'>
                            Модули
                        </label>
                        <Select onChange={(e) => setFormModules(e)}
                                isMulti={true}
                                value={formModules}
                                options={modules.map(module => ({
                                    value: module._id,
                                    label: module.title
                                }))}
                        />
                    </div>
                </div>

                <div className="formBox">
                    <button className="button greenButton">
                        Обновить
                    </button>
                </div>

            </form>

        </div>
    );
};

export default EditAssemblyForm