import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EditAssemblyForm from "../../../components/EditAssemblyForm/EditAssemblyForm";
import {getAssembliesAction, updateAssemblyAction} from "../../../redux/actions/assemblyActions";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

const EditAssemblyPage = () => {

    const {id} = useParams()

    const dispatch = useDispatch()
    const {assemblies} = useSelector(state => state.assemblyReducer)
    const {modules} = useSelector(state => state.moduleReducer)

    const navigate = useNavigate();

    const assembly = assemblies.find(assembly => assembly._id === id)

    useEffect(() => {
        if(!assemblies.length) {
            dispatch(getAssembliesAction())
        }
    }, []);



    const updateAssembly = (values) => {
        dispatch(updateAssemblyAction(id, values)).then(() => {
            toast.success('Сборка успешно обновлена', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate('/assemblies')
        }).catch(err => {
            toast.error('Ошибка при обновлении сборки: ' + err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }


    if(!assembly) {
        return <div>Загрузка...</div>
    }

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/assemblies'>Сборки</NavLink>
                <span>&rarr;</span>
                <span>{assembly.title}</span>
            </div>

            {assembly && <EditAssemblyForm data={assembly} onSubmit={updateAssembly} modules={modules}/>}
        </div>
    );
};

export default EditAssemblyPage