export const submoduleOptions = [
    {title: 'Создавать', value: 'createSubmodule'},
    {title: 'Редактировать', value: 'editSubmodule'},
    {title: 'Удалять', value: 'deleteSubmodule'}
]
export const moduleOptions = [
    {title: 'Создавать', value: 'createModule'},
    {title: 'Редактировать', value: 'editModule'},
    {title: 'Удалять', value: 'deleteModule'}
]

export const questionOptions = [
    {title: 'Создавать', value: 'createQuestion'},
    {title: 'Редактировать', value: 'editQuestion'},
    {title: 'Удалять', value: 'deleteQuestion'}
]