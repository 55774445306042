import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {getQuestionsAction, updateQuestionAction} from "../../../redux/actions/questionActions";
import EditQuestionForm from "../../../components/EditQuestionForm/EditQuestionForm";

const EditQuestionPage = () => {
    const {id, questionId} = useParams();


    useEffect(() => {
        dispatch(getQuestionsAction(id))
    }, []);

    const dispatch = useDispatch()

    const {submodules} = useSelector(state => state.submoduleReducer)
    const {questions} = useSelector(state => state.questionReducer)

    const navigate = useNavigate();

    const submoduleData = submodules.find(submodule => submodule._id === id)
    const questionData = questions.find(question => question._id === questionId)


    const handleSubmit = (values) => {
        dispatch(updateQuestionAction(questionId, values)).then(() => {
            toast.success('Вопрос успешно обновлен', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate(`/submodules/${id}/questions`)
        }).catch((err) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }



    const questionsLink = window.location.href.split('/').slice(0, -1).join('/')

    if(!questionData){
        return <div>Загрузка...</div>
    }

    return (
        <div>

            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/submodules'>Управление подмодулями</NavLink>
                <span>&rarr;</span>
                <NavLink to={`/submodules/${submoduleData?._id}`}>{submoduleData?.title}</NavLink>
                <span>&rarr;</span>
                <NavLink to={questionsLink}>Вопросы</NavLink>
                <span>&rarr;</span>
                <span>{questionData.question}</span>
            </div>

            <EditQuestionForm submoduleData={submoduleData} data={questionData} onSubmit={handleSubmit}/>
        </div>
    );
};

export default EditQuestionPage