import {
    createAssemblyRequest,
    getAssembliesRequest,
    getAssemblyRequest,
    updateAssemblyRequest
} from "../../http/assemblyRequests";

export const getAssembliesAction = () => async (dispatch) => {
    try {
        const {data} = await getAssembliesRequest();
        dispatch({type: 'SET_ASSEMBLIES', payload: data});
    } catch (error) {
        alert(error.message)
    }
}

export const getAssemblyAction = (id) => async (dispatch) => {
    try {
        const {data} = await getAssemblyRequest(id);
        dispatch({type: 'SET_ASSEMBLY', payload: data});
    } catch (error) {
        alert(error.message)
    }
}

export const createAssemblyAction = (assembly) => async (dispatch) => {
    try {
        const {data} = await createAssemblyRequest(assembly);
        dispatch({type: 'ADD_ASSEMBLY', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}

export const updateAssemblyAction = (id, formData) => async (dispatch) => {
    try {
        const {data} = await updateAssemblyRequest(id, formData);
        dispatch({type: 'UPDATE_ASSEMBLY', payload: data});
    } catch (error) {
        throw new Error(error.response.data.error)
    }
}