import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {createSubmoduleAction} from "../../../redux/actions/submoduleAction";
import {toast} from "react-toastify";


const CreateSubmodulePage = () => {
    const [title, setTitle] = useState('')
    const [idIn, setIdIn] = useState('')
    const [idOut, setIdOut] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const createSubmodule = async (e) => {
        e.preventDefault()
        if (!title) return alert('Введите название модуля')
        if (!idIn || isNaN(+idIn)) return alert('Введите правильный id in')
        if (!idOut || isNaN(+idOut)) return alert('Введите правильный id out')

        dispatch(createSubmoduleAction({title, idIn, idOut})).then(() => {
            toast.success('Подмодуль успешно создан', {
                position: "top-right",
                autoClose: 3000
            })
            navigate('/submodules')
        }).catch((err) => {
            toast.error(err.message, {
                position: "top-right",
                autoClose: 3000
            })
        })

    }

    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/submodules'>Управление подмодулями</NavLink>
                <span>&rarr;</span>
                <span>Создать</span>
            </div>
            <form className='filterForm' onSubmit={createSubmodule}>
                <div className="formFlexGroup column">

                    <div className='formBox'>
                        <label htmlFor='module'>
                            Имя подмодуля
                        </label>
                        <input type="text" id="module" value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='id_in'>
                            id in
                        </label>
                        <input type="text" id="id_in" value={idIn} onChange={(e) => setIdIn(e.target.value)}/>
                    </div>

                    <div className='formBox'>
                        <label htmlFor='id_out'>
                            id out
                        </label>
                        <input type="text" id="id_out" value={idOut} onChange={(e) => setIdOut(e.target.value)}/>
                    </div>
                </div>

                <div className="formBox">
                    <button className="button greenButton">
                        Создать
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateSubmodulePage