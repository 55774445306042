import axios from 'axios';

// const baseUrl = process.env.NODE_ENV === 'development'
//     ?  'http://localhost:5000/api' : 'https://admin.seamansbook.app/api';
const baseUrl = 'https://admin.seamansbook.app/api'

const api = axios.create({
    baseURL: baseUrl
})

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

export default api;
