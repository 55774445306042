import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import moduleReducer from "./reducers/moduleReducer";
import submoduleReducer from "./reducers/submoduleReducer";
import questionReducer from "./reducers/questionReducer";
import assemblyReducer from "./reducers/assemblyReducer";
import adminReducer from "./reducers/adminReducer";

const rootReducer = combineReducers({
    moduleReducer,
    submoduleReducer,
    questionReducer,
    assemblyReducer,
    adminReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
