const initialState = {
    modules: [],
    error: null
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_MODULES':
            return {
                ...state,
                modules: action.payload
            }
        case 'SET_MODULE':
            const index = state.modules.findIndex(module => module._id === action.payload._id);
            if (index === -1) {
                return {
                    ...state,
                    modules: [...state.modules, action.payload]
                }
            }
            return {
                ...state,
                modules: state.modules.map(module => module._id === action.payload._id ? action.payload : module)
            }
        case 'UPDATE_MODULE':
            return {
                ...state,
                modules: state.modules.map(module => module._id === action.payload._id ? action.payload : module)
            }
        case 'ADD_MODULE':
            return {
                ...state,
                modules: [...state.modules, action.payload]
            }

        case 'DELETE_MODULE':
            return {
                ...state,
                modules: state.modules.filter(module => module._id !== action.payload._id)
            }
        case 'CREATE_MODULE_FAIL':
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}