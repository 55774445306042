import api from './api';

export const getModulesRequest = async () => api.get(`/modules`);

export const getModuleRequest = async (id) => api.get(`/modules/${id}`);

export const createModuleRequest = async (module) => api.post(`/modules`, module);

export const updateModuleRequest = async (id, module) => api.put(`/modules/${id}`, module);

export const deleteModuleRequest = async (id) => api.delete(`/modules/${id}`);

