import {
    checkAuthRequest,
    createAdminRequest,
    editAdminRequest,
    getAdminsRequest,
    loginAdminRequest
} from "../../http/adminRequests";

export const getAdminsAction = () => async (dispatch) => {
    try {
        const {data} = await getAdminsRequest();
        dispatch({type: 'ADMINS_GET', payload: data});
    } catch (e) {
        throw new Error(e.response.data.error);
    }
}

export const createAdminAction = (admin) => async (dispatch) => {
    try {
        const {data} = await createAdminRequest(admin);
        dispatch({type: 'ADMIN_CREATE', payload: data});
    } catch (e) {
        throw new Error(e.response.data.error);
    }
}

export const editAdminAction = (id, admin) => async (dispatch) => {
    try {
        const {data} = await editAdminRequest(id, admin);
        dispatch({type: 'ADMIN_EDIT', payload: data});
    } catch (e) {
        throw new Error(e.response.data.error);
    }
}

export const loginAdminAction = (admin) => async (dispatch) => {
    try {
        const {data} = await loginAdminRequest(admin);
        localStorage.setItem('token', data.token);
        dispatch({type: 'ADMIN_LOGIN', payload: data.admin});
    } catch (e) {
        throw new Error(e.response.data.error);
    }
}

export const checkAuthAction = () => async (dispatch) => {
    try {
        const {data} = await checkAuthRequest();
        dispatch({type: 'ADMIN_LOGIN', payload: data});
    } catch (e) {
        throw new Error(e.response.data.error);
    }
}