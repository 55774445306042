import React, {useEffect} from 'react';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {editAdminAction, getAdminsAction} from "../../../redux/actions/adminActions";
import {useDispatch, useSelector} from "react-redux";
import EditAdminForm from "../../../components/EditAdminForm/EditAdminForm";
import {toast} from "react-toastify";

const EditAdminPage = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {admins} = useSelector(state => state.adminReducer)
    const admin = admins.find(admin => admin._id === id)

    useEffect(() => {
        if(!admin) {
            dispatch(getAdminsAction())
        }
    }, []);

    const role = useSelector(state => state.adminReducer.admin.role)
    if(role !== 'superadmin') {
        navigate('/')
        return null
    }

    const onSubmit = (data) => {
        dispatch(editAdminAction(id, data)).then(() => {
            toast.success('Админ успешно обновлен', {
                position: "top-right",
                autoClose: 3000,
            })
            navigate('/admin')
        }).catch(err => {
            toast.error('Ошибка при обновлении админа: ' + err.message, {
                position: "top-right",
                autoClose: 3000,
            })
        })
    }


    if(!admin) {
        return <div>Загрузка...</div>
    }
    return (
        <div>
            <div className='breadcrumbs'>
                <NavLink to='/'>Главная</NavLink>
                <span>&rarr;</span>
                <NavLink to='/admin'>Админка</NavLink>
                <span>&rarr;</span>
                <span>Редактировать админа {admin.login}</span>
            </div>
            <EditAdminForm data={admin} onSubmit={onSubmit}/>
        </div>
    );
};

export default EditAdminPage
