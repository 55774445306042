import React from 'react'
import './Header.css'
import {NavLink} from 'react-router-dom'
import {useSelector} from "react-redux";

export default function Header() {
    const {admin} = useSelector(state => state.adminReducer)

    const getClassName = ({isActive}) => `header_link ${isActive ? 'activeLink' : ''}`
    return (
        <div className='header'>


            <NavLink className={getClassName} to='/'>Главная</NavLink>
            {admin.role === 'superadmin' && <NavLink className={getClassName} to='/admin'>Админка</NavLink>}
            <NavLink className={getClassName} to='/modules'>Управление модулями</NavLink>
            <NavLink className={getClassName} to='/submodules'>Управление подмодулями</NavLink>
            <NavLink className={getClassName} to='/assemblies'>Сборки</NavLink>
            <NavLink className={getClassName} to='/search'>Поиск</NavLink>
        </div>
    )
}
