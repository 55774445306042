import api from './api';

export const getSubmodulesRequest = async (moduleId) => api.get(`/submodules`);

export const getSubmoduleRequest = async (id) => api.get(`/submodules/${id}`);

export const createSubmoduleRequest = async (submodule) => api.post(`/submodules`, submodule);

export const updateSubmoduleRequest = async (id, submodule) => api.put(`/submodules/${id}`, submodule);

export const deleteSubmoduleRequest = async (id) => api.delete(`/submodules/${id}`);